import type { FC } from "react";
import React from "react";

import { PageSEO } from "@pwa/features/page";

import { EmptyStatePage } from "./PageEmtpyState";

export const PageUnauthorized: FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <PageSEO
        title="Unauthorized"
        description="Sorry, you are not allowed to view this page."
        url="/unauthorized"
      />
      <EmptyStatePage label="This area is restricted. For access, contact your local admin." />
    </>
  );
};
