import { withAuthenticationRequired } from "@auth0/auth0-react";

import type {
  FeaturePageComponent,
  FeaturePageOptions,
  FeaturePageWithProps
} from "./page.model";
import { debugPage } from "./page.utils";
import { withAuthorization } from "./page.withAuthorization";
import { withPage } from "./page.withPage";

export function withAuthentication<T extends object = Record<string, unknown>>(
  PageComponent: FeaturePageComponent<T>,
  options?: FeaturePageOptions
): FeaturePageWithProps<T> {
  debugPage.debug("Authenticating...");

  // 1. Reconcile the options
  const permissions = options?.requiredPermissions ?? undefined;
  const roles = options?.restrictedRoles ?? undefined;
  const getLayout = PageComponent?.PageLayout?.getLayout || ((page) => page);

  /**
   * 2. Wrap each component
   *
   * Order of operations
   * Authorize => Authenticate => Enrich
   *
   * This is IMPORTANT. The page needs to be authenticated
   * after the layouts are passed just due to the fact that the
   * HOC will unmount and remount the components. The alternative
   * to this method is commented out below.
   */
  const PageWithBase = withPage<T>(PageComponent);
  const PageWithAuthorization = withAuthorization<T>(PageWithBase, {
    restrictedRoles: roles,
    requiredPermissions: permissions
  });
  const Page = withAuthenticationRequired<T>(
    PageWithAuthorization
  ) as FeaturePageWithProps<T>;

  Page.getLayout = getLayout;
  Page.pageProps = {
    requiredPermissions: permissions,
    restrictedRoles: roles,
    displayLoader: PageComponent.displayLoader ?? true
  };

  return Page;
}
