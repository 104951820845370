import { useMemo } from "react";

import { useGetCurrentUser } from "./auth.useGetCurrentUser";
import type { UserHasPermissionsParams } from "./auth.utils";
import { userHasAccess } from "./auth.utils";

export type UsePermissionParams = Omit<UserHasPermissionsParams, "user">;
export type UsePermissionReturn = {
  hasAccess: boolean;
  hasUserLoaded: boolean;
};
export type UsePermission = (
  params: UsePermissionParams
) => UsePermissionReturn;

export const usePermission: UsePermission = (params) => {
  const { data: user } = useGetCurrentUser();

  const hasAccess = useMemo(
    () =>
      userHasAccess({
        user,
        ...params
      }),
    [params, user]
  );

  return { hasAccess, hasUserLoaded: !!user };
};
