import { jsx as i } from "react/jsx-runtime";
import { styled as m } from "@linaria/react";
import e from "clsx";
import { useLayoutContext as n } from "./Layout.context.es.js";
/* empty css                         */
const u = /* @__PURE__ */ m("div")({
  name: "SLayoutMain",
  class: "s2nkvbx",
  propsAsIs: !1
}), x = ({
  children: o,
  className: t,
  ...a
}) => {
  const {
    layoutMainRef: s,
    mobileLayout: r
  } = n();
  return /* @__PURE__ */ i(u, {
    ...a,
    className: e(t, r),
    ref: s,
    "data-test-id": "layout-main",
    children: o
  });
};
export {
  x as LayoutMain,
  u as SLayoutMain
};
