import React from "react";

import type { FeatureLayoutComponent } from "@pwa/features/page";
import { RootLayout } from "@pwa/features/root";

export const HomePageLayout: FeatureLayoutComponent = ({ children }) => {
  return <>{children}</>;
};

HomePageLayout.getLayout = (page, props) =>
  RootLayout.getLayout(<HomePageLayout>{page}</HomePageLayout>, props);
