import { type FC } from "react";

import type { FeaturePageOptions } from "./page.model";
import { debugPage } from "./page.utils";
import { PageUnauthorized } from "./PageUnauthorized";
import { useHidePageLoader } from "./PageLoader";

import { usePermission, useRestrictedRole } from "../auth";

export function withAuthorization<
  T extends JSX.IntrinsicAttributes = Record<string, unknown>
>(PageComponent: FC<T>, options?: FeaturePageOptions) {
  const requiredPermission = options?.requiredPermissions ?? undefined;
  const restrictedRoles = options?.restrictedRoles ?? undefined;

  const permissionString = requiredPermission?.toString();
  const rolesString = restrictedRoles?.toString();
  debugPage.debug(`Requires permissions: "${permissionString || "none"}".`);
  debugPage.debug(`Restricted roles: "${rolesString || "none"} .`);

  const AuthorizedPage: FC<T> = (props) => {
    debugPage.debug(`Authorizing...`);

    const { hasAccess, hasUserLoaded: hasUserPermissionLoaded } = usePermission(
      {
        requiredPermissions: requiredPermission
      }
    );
    const { hasAccess: hasRoleAccess, hasUserLoaded: hasUserRoleLoaded } =
      useRestrictedRole(restrictedRoles);

    const hideLoader = useHidePageLoader(
      !hasUserRoleLoaded,
      !hasUserPermissionLoaded
    );

    if (!hasUserRoleLoaded || !hasUserPermissionLoaded) {
      return null;
    }

    if (typeof requiredPermission === "undefined") {
      hideLoader();
    }

    if (!hasAccess || !hasRoleAccess) {
      debugPage.debug(
        `Unauthorized. Page requires permissions "${permissionString}".`
      );
      debugPage.debug(
        `Unauthorized. Page restricted from roles "${rolesString}".`
      );
      return <PageUnauthorized />;
    }

    return <PageComponent {...props} />;
  };

  return AuthorizedPage;
}
