import { jsx as s } from "react/jsx-runtime";
import { styled as r } from "@linaria/react";
import { useLayoutContext as e } from "./Layout.context.es.js";
/* empty css                        */
const m = /* @__PURE__ */ r("nav")({
  name: "SLayoutNav",
  class: "soa799p",
  propsAsIs: !1
}), f = ({
  children: t,
  ...o
}) => {
  const {
    layoutNavRef: a
  } = e();
  return /* @__PURE__ */ s(m, {
    ...o,
    ref: a,
    "data-test-id": "layout-navbar",
    children: t
  });
};
export {
  f as LayoutNav,
  m as SLayoutNav
};
