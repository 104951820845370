import { useEffect } from "react";
import { useRouter } from "next/router";

import type { FeaturePageComponent } from "@pwa/features/page";
import { withAuthentication } from "@pwa/features/page";

import { HomePageLayout } from "./Home.layout";

import { useGetCurrentUser } from "../auth";

/**
 * This page is a placeholder for the moment where the user
 * will be able to see a dashboard of pertinent information.
 *
 * However, at the moment, it is used for redirecting the user
 * to the pages that matter the most for them based upon their
 * permissions.
 */
const Page: FeaturePageComponent = () => {
  const { data } = useGetCurrentUser();
  const { push } = useRouter();

  // When the current user becomes available, we need to figure out
  // where we're going to route them. Based upon some of the values
  // below we're going to redirect the user to specific pages
  useEffect(() => {
    if (!data) return;
    const canViewAssets = data.group.permissions.includes("view_asset");

    if (canViewAssets) {
      push("/assets?ordering=description", undefined, { shallow: true });
      return;
    }
    push("/monitoring", undefined, { shallow: true });
    return;
  }, [data, push]);

  return null;
};

Page.PageLayout = HomePageLayout;
Page.meta = {
  title: "Loading...",
  description: "Welcome",
  url: "/"
};

export const HomePage = withAuthentication(Page);
