import type { GetUserByIdApiResponse, UserPermissions } from "@machineq/models";

export type UserHasPermissionsParams = {
  user: GetUserByIdApiResponse | undefined;
  /**
   * The children components will only display if the user
   * has all of the permissions listed here. If `undefined`
   * then the component will assume the user has access
   * to the children
   */
  requiredPermissions: UserPermissions | undefined;
  /**
   * Changes the logic on how the requiredPermissions prop
   * is processed.
   * - `AND` - all permissions must be met
   * - `OR` - only one permission must be met
   */
  logicalOperator?: "AND" | "OR";
};
export const userHasAccess = ({
  user,
  requiredPermissions,
  logicalOperator = "AND"
}: UserHasPermissionsParams): boolean => {
  if (user?.role === "api-user") return false;

  if (typeof requiredPermissions === "undefined") {
    return true;
  }

  if (
    typeof requiredPermissions === "string" &&
    requiredPermissions === "super-user"
  ) {
    return user?.role === "mq-admin";
  }

  const arrBool = requiredPermissions.map((permission) => {
    if (user?.group.permissions.includes(permission)) {
      return true;
    }
    return false;
  });

  if (logicalOperator === "AND") {
    return arrBool.every((perm) => !!perm);
  }

  return arrBool.includes(true);
};
