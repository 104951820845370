import type { UserRoles } from "@machineq/models";
import { useMemo } from "react";

import { useGetCurrentUser } from "./auth.useGetCurrentUser";

export type UseRoleReturn = {
  hasAccess: boolean;
  hasUserLoaded: boolean;
};
export type UseRole = (params: UserRoles[] | undefined) => UseRoleReturn;

/**
 * Determines if the user's role has access or not. If the user's role
 * is included in the `restrictedRoles` param, then the `hasAccess`
 * param should return false
 */
export const useRestrictedRole: UseRole = (restrictedRoles) => {
  const { data: user } = useGetCurrentUser();

  const hasAccess = useMemo(() => {
    if (typeof restrictedRoles === "undefined") {
      return true;
    }

    if (user?.role === "api-user") return false;

    const arrBool = restrictedRoles.map((roles) => {
      if (user?.role.includes(roles)) {
        return false;
      }
      return true;
    });

    return arrBool.includes(true);
  }, [restrictedRoles, user?.role]);

  return { hasAccess, hasUserLoaded: !!user };
};
